import React from "react"
import styled from "@emotion/styled"
import WorkItem from "./WorkItem"

const WorkList = ({works}) => {
  return (
    <Wrapper>
      { works.map(work => <WorkItem key={work.node.slug} work={work} />) }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;
`

export default WorkList