import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const WorkItem = ({work}) => {
  return (
    <Wrapper>
      <Link to={`/works/${work.node.slug}`}>
        <Item color={work.node.color}>
          <MainImg>
            <img src={work.node.keyImage.resize.src} alt={work.node.title} />
          </MainImg>
          <Title>{work.node.title}</Title>
          <Credit>
            <span>{work.node.roles[0]}</span>
            <span>{work.node.year}</span>
          </Credit>
        </Item>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-basis: 33.3%;
  max-width: 33.3%;
  padding: 32px 32px;
  a {
    text-decoration: none;
    color: inherit;
  }

  @media ${device.mobile} {
    flex-basis: 50%;
    max-width: 50%;
    padding: 16px 16px;
  }
`

const Item = styled.div`
  padding: 32px 24px;
  border: 1px solid transparent;
  transition: all 0.5s ease;

  &:hover {
    border: 1px solid ${props => props.color };
    transform: scale(1.05);
    cursor: pointer;
  }

  @media ${device.mobile} {
    padding: 8px 8px;
  }
`

const MainImg = styled.div`
  img {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;


    @media ${device.mobile} {
      height: 100px;
    }
  }
`
const Title = styled.h3`
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 16px;

  @media ${device.mobile} {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 4px;
  }
`

const Credit = styled.div`
  display: block;
  span {
    font-size: 12px;
    margin-right: 8px;
    color: ${props => props.theme.colors.baseGrayColor};

    @media ${device.mobile} {
      font-size: 10px;
    }
  }
`

export default WorkItem
